import React, {
  useContext,
  useReducer,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useLayoutEffect,
  useState
} from "react"
import PropTypes from "prop-types"
import {
  get,
  find,
  isEmpty,
  uniqBy,
  uniq,
  toLower,
  filter,
  cloneDeep,
  map,
  capitalize,
  includes,
  concat
} from "lodash"
import { object } from "yup"
import moment from "moment"
import { useFormik } from "formik"
import { useTranslations } from "@4cplatform/elements/Translations"
import { useGet, useDelete, usePost, PusherContext } from "@4cplatform/elements/Api"
import { useParams } from "react-router-dom"

// Helpers

import { ConfigContext } from "@4cplatform/elements/Config"
import { PageContext } from "@4cplatform/elements/Organisms"
import { addAlert } from "@4cplatform/elements/Alerts"
import {
  editQuoteSchemaModel,
  sortQuotesByPremium,
  mapAvailableQuoteOptionsToEditQuoteSchema,
  transformAvailableOptionKeysToHumanReadable
} from "../quotationSummary.helpers"
import reducer from "./quotationSummary.reducer"
import { JourneyContext } from "../../../../../../../journey.context"
import { getName, getPageAudit } from "../../../../../../../../../Helpers"

// Components
import { Provider } from "./quotationSummary.context"

const QuotationSummaryProvider = ({ children }) => {
  const { client: pusher } = useContext(PusherContext)
  const { DEBUG } = useContext(ConfigContext)
  const {
    data,
    formik: journeyFormik,
    isLoading: isJourneyLoading,
    onClickPrevious: journeyOnClickPrevious,
    updateJourneyAuditData,
    formik,
    duration,
    resetDuration
  } = useContext(JourneyContext)
  const {
    selfServiceData,
    panelStatusControls: { panelStatus },
    setPanelStatus
  } = useContext(PageContext)
  const t = useTranslations()
  const firstUpdate = useRef(true)
  const [firstLoad, setFirstLoad] = useState(true)
  const { reference } = useParams()
  const applicantsJourney = get(data, "journey.applicants", [])
  const [
    {
      quotes,
      journeyApplicants,
      selectedQuote,
      eligibilityModal,
      selectedEditQuote,
      selectedEditQuoteMembers,
      selectedEditQuoteDealCodes,
      tableLoading,
      quotesLoadingStates,
      demandsNeeds,
      demandsNeedsModal,
      deleteQuoteModal,
      quoteNetworkDocuments,
      quoteNetworkDocumentsModal,
      availableQuoteOptions,
      editQuoteValidationSchema,
      showNoChildOnlyPolicyError,
      showDependantOnlyPolicyMessage,
      showPartnerPolicyMessage,
      showNoApplicantsError,
      fetchQuote,
      quotePushedToClientID,
      providerInfoModal,
      isApplicantsChanged,
      IsDealCodesChanged
    },
    dispatch
  ] = useReducer(reducer, {
    quotes: sortQuotesByPremium(get(data, "payload", [])),
    journeyApplicants: [],
    selectedQuote: null,
    eligibilityModal: false,
    selectedEditQuote: null,
    selectedEditQuoteMembers: [],
    selectedEditQuoteDealCodes: [],
    quotesLoadingStates: [],
    tableLoading: false,
    demandsNeeds: null,
    quoteNetworkDocuments: null,
    quoteNetworkDocumentsModal: false,
    demandsNeedsModal: false,
    deleteQuoteModal: { show: false, quoteId: null },
    availableQuoteOptions: null,
    editQuoteValidationSchema: editQuoteSchemaModel,
    showNoChildOnlyPolicyError: false,
    showDependantOnlyPolicyMessage: false,
    showPartnerPolicyMessage: false,
    showNoApplicantsError: false,
    fetchQuote: null,
    quotePushedToClientID: null,
    providerInfoModal: { open: false, data: {} },
    isApplicantsChanged: true,
    IsDealCodesChanged: true
  })

  const dataQuoteTable = useMemo(() => {
    const initialQuoteApplicants = get(selectedEditQuote, "applicants", [])
    const journeyApplicantsNotInQuote = []

    journeyApplicants.forEach(journeyApplicant => {
      if (
        !initialQuoteApplicants.filter(_ => _.journey_applicant_id === journeyApplicant.id).length
      )
        journeyApplicantsNotInQuote.push({
          ...journeyApplicant,
          journey_applicant_id: journeyApplicant.id
        })
    })

    return [...initialQuoteApplicants, ...journeyApplicantsNotInQuote]
  }, [selectedEditQuote, journeyApplicants])

  const editQuoteFormAvailableOptionsInitialValues = {}

  // Set initial values for edit quote formik instance
  availableQuoteOptions &&
    Object.keys(availableQuoteOptions?.available_options).forEach(availableOptionKey => {
      const fieldKey = availableOptionKey.split(" ").join("_")

      // Build initial values. Default to Fixed for Hospital List on AXA
      editQuoteFormAvailableOptionsInitialValues[fieldKey] =
        get(selectedEditQuote, "provider.provider_key", false) === "AXA" &&
        fieldKey === "Hospital_List"
          ? "Fixed"
          : find(selectedEditQuote?.options, _ => _.name === availableOptionKey, {})?.value
    })
  // Filter journey applicant arrays and quote applicant arrays to find applicant that has cancer
  const filterApplicantsHavingCancer = (journeyArrays, quoteArrays) =>
    filter(journeyArrays, applicant => {
      const matchingItem = includes(quoteArrays, applicant.id)
      const isApplicantHasCancer = get(applicant, "answers.last_5_years_cancer")
      return matchingItem && isApplicantHasCancer
    })
  /**
   * Updates the members formik field depending on whether the applicant is checked in the applicants table
   * @param {bool} checked
   * @param {number} memberId
   */
  const updateQuoteApplicants = (checked = false, member = null, primary = null) => {
    if (!member) return

    let quoteMembers = cloneDeep(selectedEditQuoteMembers)

    if (checked) {
      quoteMembers.push(member)
    } else {
      quoteMembers = quoteMembers.filter(_ => _.member_id !== member.member_id)
    }

    if (primary) {
      quoteMembers.push(primary)
      // eslint-disable-next-line no-use-before-define
      editQuoteFormik.setFieldValue(
        "members",
        // eslint-disable-next-line no-use-before-define
        uniqBy([...get(editQuoteFormik, "values.members"), primary.member_id])
      )
    }

    dispatch({
      type: "UPDATE_VALUE",
      key: "selectedEditQuoteMembers",
      value: uniqBy(quoteMembers, "member_id")
    })

    // eslint-disable-next-line no-use-before-define
    editQuoteFormik.handleSubmit()
  }

  /**
   * Updates the deal codes formik field depending on whether the applicant is checked in the deal codes table
   * @param {bool} checked
   * @param {number} quoteDealCode
   */
  const updateQuoteDealCodes = (checked = false, quoteDealCode = null) => {
    if (!quoteDealCode) return

    let quoteDealCodes = cloneDeep(selectedEditQuoteDealCodes)

    if (checked) {
      quoteDealCodes.push(quoteDealCode)
    } else {
      quoteDealCodes = quoteDealCodes.filter(_ => _ !== quoteDealCode)
    }

    // eslint-disable-next-line no-use-before-define
    editQuoteFormik.setFieldValue("deal_codes", quoteDealCodes)
    dispatch({
      type: "UPDATE_VALUE",
      key: "selectedEditQuoteDealCodes",
      value: quoteDealCodes
    })

    // eslint-disable-next-line no-use-before-define
    editQuoteFormik.handleSubmit()
  }

  // Journey applicants query
  const { loading: journeyApplicantsLoading, error: journeyApplicantsError } = useGet({
    endpoint: "/journeys/:slug/applicants",
    params: {
      slug: get(data, "journey.slug")
    },
    query: {
      limit: 10,
      with: ["journeyData"]
    },
    onCompleted: res => {
      const journeyApplicantsArr = get(res, "data", []).map(journeyApplicant => {
        // Creates a new member_id key if it doesn't exist - this will be the key passed into the members param in payload. This is required because applicants not included in the quote but are in the journey do not have journey_applicant_id key.
        journeyApplicant.member_id = journeyApplicant.id
        return journeyApplicant
      })

      dispatch({
        type: "UPDATE_VALUE",
        key: "journeyApplicants",
        value: journeyApplicantsArr
      })
    }
  })

  const quotesSubscriptionCallback = useCallback(
    quotesRes => {
      const pusherData = get(JSON.parse(get(quotesRes, "data", {})), "data", {})
      const isSuccess = get(JSON.parse(get(quotesRes, "data", {})), "success", false)

      if (DEBUG) {
        // eslint-disable-next-line no-console
        console.log(
          `${get(pusherData, "provider.name")} ${get(pusherData, "product")} quote ${
            isSuccess ? "arrived:" : "errored out:"
          }`,
          pusherData || "Not valid JSON",
          quotesRes
        )
      }

      if (isSuccess) {
        dispatch({
          type: "UPDATE_VALUE",
          key: "tableLoading",
          value: true
        })

        dispatch({
          type: "UPDATE_VALUE",
          key: "fetchQuote",
          value: pusherData
        })
      } else {
        if (selectedEditQuote) {
          // eslint-disable-next-line no-use-before-define
          editQuoteFormik.setValues(editQuoteFormInitialValues, false)
        }

        addAlert({
          type: "error",
          message: t("QUOTATION_FETCH_ERROR"),
          dismissible: true,
          timeout: 5
        })

        dispatch({
          type: "UPDATE_VALUE",
          key: "tableLoading",
          value: false
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [DEBUG, addAlert, selectedEditQuote, t]
  )

  useEffect(() => {
    const channel = pusher.subscribe(`private-encrypted-user.${get(selfServiceData, "id")}`)

    channel.bind(
      `4cng.${toLower(get(data, "journey.product_type", "PMI"))}_quote_update-response`,
      quotesSubscriptionCallback
    )

    return () => pusher.unsubscribe(`private-encrypted-user.${get(selfServiceData, "id")}`)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Refresh quote
  const [refreshQuote, { loading: refreshQuoteLoading }] = usePost({
    endpoint: "/journeys/:journey/quotation-summary/refresh-quote",
    params: {
      journey: get(data, "journey.slug", "")
    },
    onCompleted: () => {
      dispatch({
        type: "UPDATE_VALUE",
        key: "tableLoading",
        value: true
      })
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("QUOTATION_REFRESH_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Push quote to client
  const [pushQuoteToClient, { loading: pushQuoteToClientLoading }] = usePost({
    endpoint: "/journeys/:journey/quotation-summary/push-to-client",
    params: {
      journey: get(data, "journey.slug", "")
    },
    onCompleted: () => {
      if (quotePushedToClientID)
        dispatch({
          type: "UPDATE_QUOTE_SENT_TO_CLIENT_STATE",
          key: "quotes",
          value: { quoteId: quotePushedToClientID, sentToClient: true }
        })

      dispatch({
        type: "UPDATE_VALUE",
        key: "quotePushedToClientID",
        value: null
      })

      addAlert({
        type: "success",
        message: t("QUOTATION_PUSH_TO_CLIENT_SUCCESS"),
        dismissible: true,
        timeout: 5
      })
    },
    onError: () => {
      if (quotePushedToClientID)
        dispatch({
          type: "UPDATE_QUOTE_SENT_TO_CLIENT_STATE",
          key: "quotes",
          value: { quoteId: quotePushedToClientID, sentToClient: false }
        })

      dispatch({
        type: "UPDATE_VALUE",
        key: "quotePushedToClientID",
        value: null
      })

      addAlert({
        type: "error",
        message: t("QUOTATION_PUSH_TO_CLIENT_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Copy quote
  const [copyQuote, { loading: copyQuoteLoading }] = usePost({
    endpoint: "/journeys/:journey/quotation-summary/copy-quote",
    params: {
      journey: get(data, "journey.slug", "")
    },
    onCompleted: res => {
      dispatch({ type: "ADD_QUOTE", key: "quotes", value: get(res, "data", []) })

      addAlert({
        type: "success",
        message: t("QUOTATION_COPY_SUCCESS"),
        dismissible: true,
        timeout: 5
      })
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("QUOTATION_COPY_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Delete quote
  const [deleteQuote, { loading: deleteQuoteLoading, error: deleteQuoteError }] = useDelete({
    endpoint: "/journeys/:journey/quotation-summary/delete-quote",
    params: {
      journey: get(data, "journey.slug", "")
    },
    onCompleted: () => {
      if (deleteQuoteModal?.quoteId)
        dispatch({ type: "DELETE_QUOTE", key: "quotes", value: deleteQuoteModal.quoteId })

      if (deleteQuoteModal?.quoteId === selectedQuote?.id)
        dispatch({
          type: "UPDATE_VALUE",
          key: "selectedQuote",
          value: null
        })

      dispatch({
        type: "UPDATE_VALUE",
        key: "deleteQuoteModal",
        value: { show: false, quoteId: null }
      })

      addAlert({
        type: "success",
        message: t("QUOTATION_DELETE_SUCCESS"),
        dismissible: true,
        timeout: 5
      })
    },
    onError: () => {
      dispatch({
        type: "UPDATE_VALUE",
        key: "deleteQuoteModal",
        value: { show: false, quoteId: null }
      })

      addAlert({
        type: "error",
        message: t("QUOTATION_DELETE_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Update demands and needs
  const [updateDemandsNeeds, { loading: updateDemandsNeedsLoading }] = usePost({
    endpoint: "/journeys/:journey/quotation-summary/update-custom-text",
    params: {
      journey: get(data, "journey.slug", "")
    },
    onCompleted: () => {
      dispatch({
        type: "UPDATE_QUOTE_LOADING_STATE",
        key: "quotesLoadingStates",
        value: { quoteId: demandsNeeds.id, loading: false }
      })

      const optionsObj = {}
      get(demandsNeeds, "options", []).forEach(option => (optionsObj[option.name] = option.value))

      const body = {
        quote_id: get(demandsNeeds, "id", ""),
        members: get(demandsNeeds, "applicants", []).map(
          applicant => applicant.journey_applicant_id
        ),
        underwriting_style: get(demandsNeeds, "underwriting_style", ""),
        underwriting_type: get(demandsNeeds, "underwriting_type", ""),
        payment_frequency: get(demandsNeeds, "payment_frequency", ""),
        start_date: get(demandsNeeds, "start_date", ""),
        expiry_date: get(demandsNeeds, "expiry_date", ""),
        options: optionsObj
      }

      refreshQuote({ body })

      dispatch({ type: "UPDATE_VALUE", key: "demandsNeeds", value: null })
      dispatch({ type: "UPDATE_VALUE", key: "demandsNeedsModal", value: false })

      addAlert({
        type: "success",
        message: t("QUOTATION_DEMANDS_NEEDS_UPDATE_SUCCESS"),
        dismissible: true,
        timeout: 5
      })

      addAlert({
        type: "success",
        message: t("QUOTATION_REFRESHING_QUOTE"),
        dismissible: false
      })
    },
    onError: () => {
      dispatch({
        type: "UPDATE_QUOTE_LOADING_STATE",
        key: "quotesLoadingStates",
        value: { quoteId: demandsNeeds.id, loading: false }
      })

      addAlert({
        type: "error",
        message: t("QUOTATION_DEMANDS_NEEDS_UPDATE_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Fetch single quote (called each time edit flyout panel is called - to circumvent issue of outdated details after pusher response)
  const { data: fetchedQuote, loading: fetchQuoteLoading } = useGet({
    endpoint: "/quotes/:quote",
    params: {
      quote: get(fetchQuote, "quote_slug", "")
    },
    query: {
      with: ["applicants.premiums"]
    },
    onCompleted: res => {
      dispatch({
        type: "UPDATE_QUOTE",
        key: "quotes",
        value: { quoteId: get(res, "data.id"), data: get(res, "data") }
      })

      if (selectedEditQuote)
        dispatch({
          type: "UPDATE_VALUE",
          key: "selectedEditQuote",
          value: get(res, "data")
        })

      dispatch({
        type: "UPDATE_QUOTE_LOADING_STATE",
        key: "quotesLoadingStates",
        value: { quoteId: get(res, "data.id"), loading: false }
      })

      dispatch({
        type: "UPDATE_VALUE",
        key: "tableLoading",
        value: false
      })

      dispatch({
        type: "UPDATE_VALUE",
        key: "fetchQuote",
        value: null
      })
    },
    onError: () => {
      if (selectedEditQuote)
        // eslint-disable-next-line no-use-before-define
        editQuoteFormik.setValues(editQuoteFormInitialValues, false)

      addAlert({
        type: "error",
        message: t("QUOTE_FETCH_ERROR"),
        dismissible: true,
        timeout: 5
      })
    },
    skip: !get(fetchQuote, "quote_slug")
  })

  // Available options query
  const [
    availableOptionsRefetch,
    { loading: availableOptionsLoading, error: availableOptionsError }
  ] = usePost({
    endpoint: "/journeys/:journey/quotation-summary/available-options",
    params: {
      journey: get(data, "journey.slug", "")
    },
    onCompleted: res => {
      dispatch({
        type: "UPDATE_VALUE",
        key: "availableQuoteOptions",
        value: get(res, "data", null)
      })
    },
    onError: () => {
      if (panelStatus !== "closed") setPanelStatus("closed")

      addAlert({
        type: "error",
        message: t("QUOTATION_AVAILABLE_OPTIONS_ERROR"),
        dismissible: true,
        timeout: 5
      })
    },
    skip: !fetchedQuote || !get(data, "journey.slug")
  })

  const editQuoteFormInitialValues = {
    quote_id: get(selectedEditQuote, "id", null),
    members: !isEmpty(selectedEditQuoteMembers)
      ? selectedEditQuoteMembers.map(_ => _.journey_applicant_id)
      : [],
    underwriting_type: get(selectedEditQuote, "underwriting_type", ""),
    underwriting_style: get(selectedEditQuote, "underwriting_style", ""),
    payment_frequency: get(selectedEditQuote, "payment_frequency", ""),
    options: {
      ...editQuoteFormAvailableOptionsInitialValues
    },
    deal_codes: !isEmpty(selectedEditQuoteDealCodes) ? selectedEditQuoteDealCodes : []
  }

  // Edit quote formik instance
  const editQuoteFormikInstance = useFormik({
    initialValues: editQuoteFormInitialValues,
    validationSchema: editQuoteValidationSchema,
    enableReinitialize: true,
    onSubmit: ({ options, members, ...rest }) => {
      const isVitality = get(selectedEditQuote, "provider.provider_key", false) === "VITALITYHEALTH"
      const isAxa = get(selectedEditQuote, "provider.provider_key", false) === "AXA"
      if (isVitality && !options.Worldwide_Travel) {
        options.Worldwide_Travel = "No"
      }
      const listApplicantHasCancer = filterApplicantsHavingCancer(applicantsJourney, members)
      if (isAxa) {
        if (listApplicantHasCancer.length === 0) {
          options.Cancer_Cover = "Full"
        } else {
          options.Cancer_Cover = "NHS Support"
        }
      }

      const body = {
        options: transformAvailableOptionKeysToHumanReadable(options),
        members,
        ...rest,
        deal_codes: selectedEditQuoteDealCodes
      }

      refreshQuote({ body })
    }
  })

  const editQuoteFormik = {
    ...editQuoteFormikInstance,
    validationSchema: editQuoteValidationSchema
  }

  /**
   * Returns an applicant by type, only filtering by those applicants that are currently checked in the applicants table
   * @param {string} type
   * @returns {}
   */
  const getApplicantByType = (type = null, filtering = "included", returnAll = false) => {
    if (!type) return

    const memberIds = get(editQuoteFormik, "values.members", [])
    let applicant = null
    const method = returnAll ? filter : find

    memberIds.forEach(memberId => {
      if (
        filtering === "included" &&
        find(selectedEditQuoteMembers, _ => _.member_id === memberId && _.type === type, null)
      )
        applicant = method(
          selectedEditQuoteMembers,
          _ => _.member_id === memberId && _.type === type
        )
      else if (filtering === "all" && find(dataQuoteTable, _ => _.type === type, null))
        applicant = method(dataQuoteTable, _ => _.type === type)
    })

    return applicant
  }

  // refetch available options each time selectedEditQuote state updates
  useEffect(() => {
    if (!selectedEditQuote) return

    if (panelStatus !== "wide") setPanelStatus("wide")

    const quoteOptions = {}
    selectedEditQuote?.options.map(option => (quoteOptions[`${option.name}`] = option.value))

    const body = {
      quote_id: get(selectedEditQuote, "id", ""),
      underwriting_style: get(selectedEditQuote, "underwriting_style", ""),
      underwriting_type: get(selectedEditQuote, "underwriting_type", ""),
      current_options: {
        ...quoteOptions
      },
      deal_codes: !isEmpty(selectedEditQuoteDealCodes) ? selectedEditQuoteDealCodes : []
    }

    availableOptionsRefetch({ body })

    dispatch({
      type: "UPDATE_VALUE",
      key: "selectedEditQuoteMembers",
      value: [
        ...get(selectedEditQuote, "applicants", []).map(_ => {
          _.member_id = _.journey_applicant_id || _.id
          return _
        })
      ]
    })

    dispatch({
      type: "UPDATE_VALUE",
      key: "selectedEditQuoteDealCodes",
      value: [
        ...get(selectedEditQuote, "deal_codes", []).map(_ => {
          _.deal_code_id = _.deal_code.id || _.id
          return _.deal_code_id
        })
      ]
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEditQuote])

  // Map available quote options to edit quote form validation schema
  useEffect(() => {
    if (!availableQuoteOptions) return

    dispatch({
      type: "UPDATE_VALUE",
      key: "editQuoteValidationSchema",
      value: editQuoteSchemaModel.concat(
        object({
          options: object().shape({
            ...mapAvailableQuoteOptionsToEditQuoteSchema(availableQuoteOptions)
          })
        })
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableQuoteOptions])

  // Fires everytime editQuoteFormik values change
  useEffect(() => {
    if (!getApplicantByType("primary") && getApplicantByType("partner")) {
      dispatch({ type: "UPDATE_VALUE", key: "showDependantOnlyPolicyMessage", value: false })
      return dispatch({ type: "UPDATE_VALUE", key: "showPartnerPolicyMessage", value: true })
    }

    const hasAdultDependants = !isEmpty(
      filter(getApplicantByType("dependant", "included", true), a => a.rate === "adult")
    )

    if (!getApplicantByType("primary") && hasAdultDependants) {
      dispatch({ type: "UPDATE_VALUE", key: "showPartnerPolicyMessage", value: false })
      return dispatch({ type: "UPDATE_VALUE", key: "showDependantOnlyPolicyMessage", value: true })
    }

    // otherwise reset all policy messaging
    if (showDependantOnlyPolicyMessage)
      dispatch({ type: "UPDATE_VALUE", key: "showDependantOnlyPolicyMessage", value: false })

    if (showPartnerPolicyMessage)
      dispatch({ type: "UPDATE_VALUE", key: "showPartnerPolicyMessage", value: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editQuoteFormik.values])

  const resetEditQuoteForm = () => {
    editQuoteFormik.resetForm()

    dispatch({ type: "RESET_EDIT_QUOTE_FORM" })
  }

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    if (panelStatus === "closed") resetEditQuoteForm()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelStatus])

  useEffect(() => {
    const theSelectedQuote = find(get(data, "payload", []), "selected")

    if (!isEmpty(theSelectedQuote))
      dispatch({ type: "UPDATE_VALUE", key: "selectedQuote", value: theSelectedQuote })
  }, [data])

  useEffect(() => {
    journeyFormik.setFieldValue("selected_quote", get(selectedQuote, "id", null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuote])

  useEffect(() => {
    if (quotePushedToClientID) pushQuoteToClient({ body: { quote_id: quotePushedToClientID } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotePushedToClientID])

  function groupPoliciesByProvider(policies) {
    const groupedPolicies = []
    policies.forEach(policy => {
      const providerName = policy.provider.name
      const isVitality = get(policy, "provider.provider_key", false) === "VITALITYHEALTH"
      const isSwitchSave = get(policy, "quote_type", null) === "switch_save"
      const isMoriPlus = get(policy, "flags.VITALITY_MORI_PLUS", false)
      let extraDiscount = ""
      let vitalityUnderWritingType = t(get(policy, "underwriting_type", ""))
      if (isMoriPlus) {
        vitalityUnderWritingType = "Moratorium+"
      }
      const isVitalityTravelCoverNotAvailable =
        isVitality &&
        get(policy, "applicants", []).filter(applicant => {
          const dob = moment.utc(get(applicant, "date_of_birth"), "YYYY-MM-DD HH:mm").local()
          const age = moment().diff(dob, "years")
          return age >= 65
        }).length > 0

      if (isVitality) {
        if (isSwitchSave) {
          extraDiscount = "Switch & Save"
        }
        if (isMoriPlus) {
          extraDiscount = "Mori+ Discount Applied"
        }
      }
      const trimObject = {
        product_name: t(get(policy, "product_name", "")),
        reference: get(policy, "reference"),
        underwriting_style: capitalize(get(policy, "underwriting_style", "")),
        underwriting_type: isVitality
          ? vitalityUnderWritingType
          : t(get(policy, "underwriting_type", "")),
        extraDiscount,
        member: map(get(policy, "applicants"), applicant =>
          [applicant.first_name, applicant.middles_names, applicant.last_name]
            .filter(name => !!name)
            .join(" ")
        ),
        start_date: moment(get(policy, "start_date")).format("Do MMMM YYYY"),
        payment_frequency: capitalize(t(get(policy, "payment_frequency", ""))),
        hospital_list: get(policy, "hospital_list"),
        excess: get(policy, "excess") === "£0" ? "zero" : get(policy, "excess"),
        outpatient_limit:
          get(policy, "outpatient_limit") === "Nil" ? "None" : get(policy, "outpatient_limit"),
        cancer_cover: get(policy, "cancer_cover"),
        dental_cover: get(policy, "dental_cover"),
        therapies_cover: get(policy, "therapies_cover"),
        psychiatric_cover: get(policy, "psychiatric_cover"),
        guided_care: get(policy, "guided_care", ""),
        in_budget: get(policy, "in_budget", false) ? "Yes" : "No",
        protectedNCD: find(policy.options, _ => _.name === "Protected NCD", {})?.value || "N/A",
        sixWeekOption: find(policy.options, _ => _.name === "Six Week Option", {})?.value || "N/A",
        selected: get(selectedQuote, "id", null) === get(policy, "id") ? "Yes" : "No",
        monthly: `£${parseFloat(get(policy, "monthly_premium")).toFixed(2)}`,
        yearly: `£${parseFloat(get(policy, "annual_premium")).toFixed(2)}`,
        travelCover:
          find(policy.options, _ => _.name === "Travel Cover", {})?.value ||
          find(policy.options, _ => _.name === "Travel", {})?.value ||
          (!isVitalityTravelCoverNotAvailable &&
            find(policy.options, _ => _.name === "Worldwide Travel", {})?.value) ||
          "N/A"
      }

      const provider = {
        name: providerName,
        value: trimObject
      }
      groupedPolicies.push(provider)
    })
    return groupedPolicies
  }

  function groupSelectedDealCodesByProvider(policies) {
    let groupedSelectedDealCodes = []
    let joinedSelectedDealCodes = ""
    policies.forEach(policy => {
      const dealCodes = get(policy, "deal_codes", [])
      if (dealCodes.length > 0) {
        const dealCodeName = dealCodes.map(dealCode => get(dealCode, "deal_code.name", ""))
        groupedSelectedDealCodes = concat(groupedSelectedDealCodes, dealCodeName)
      }
    })
    if (groupedSelectedDealCodes.length > 0) {
      joinedSelectedDealCodes = uniq(groupedSelectedDealCodes).join(", ")
    }

    return joinedSelectedDealCodes
      ? [
          {
            name: "Deal codes applied",
            value: joinedSelectedDealCodes
          }
        ]
      : []
  }
  const generateAuditData = currentQuotes => [
    {
      mode: "remove",
      data: [{ name: "Selected quote" }]
    },
    {
      mode: "append",
      data: groupPoliciesByProvider(currentQuotes)
    },
    {
      mode: "append",
      data: groupSelectedDealCodesByProvider(currentQuotes)
    }
  ]

  const [submitAudit] = usePost({
    endpoint: "/journeys/:reference/audits",
    params: {
      reference
    }
  })

  const manuallyUpdateAudit = () => {
    const auditData = generateAuditData(quotes)

    const pageAudit = {
      duration: firstLoad ? 0 : moment().diff(duration, "seconds"),
      ...getPageAudit({
        journeyData: data,
        formikValues: get(formik, "values"),
        auditData
      })
    }
    if (firstLoad) {
      setFirstLoad(false)
    }
    submitAudit({ body: pageAudit })
    resetDuration()
  }

  useEffect(() => {
    updateJourneyAuditData(generateAuditData(quotes))

    return () => updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotes, selectedQuote])

  useLayoutEffect(() => {
    if (quotes) {
      setTimeout(() => {
        manuallyUpdateAudit()
      }, 800)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotes])

  return (
    <Provider
      value={{
        isJourneyLoading,
        journeyOnClickPrevious,
        editQuoteFormik,
        quotes,
        journeyApplicants,
        journeyApplicantsError,
        journeyApplicantsLoading,
        selectedQuote,
        selectedEditQuote,
        selectedEditQuoteMembers,
        eligibilityModal,
        fetchedQuote,
        fetchQuoteLoading,
        quotesLoadingStates,
        tableLoading,
        demandsNeeds,
        updateDemandsNeedsLoading,
        demandsNeedsModal,
        quoteNetworkDocuments,
        quoteNetworkDocumentsModal,
        deleteQuoteModal,
        availableOptionsLoading,
        availableQuoteOptions,
        availableOptionsError,
        editQuoteValidationSchema,
        refreshQuoteLoading,
        showNoChildOnlyPolicyError,
        showDependantOnlyPolicyMessage,
        showPartnerPolicyMessage,
        showNoApplicantsError,
        deleteQuoteLoading,
        deleteQuoteError,
        pushQuoteToClientLoading,
        copyQuoteLoading,
        isApplicantsChanged,
        IsDealCodesChanged,
        onDemandsNeedsUpdate: body => {
          dispatch({
            type: "UPDATE_QUOTE_LOADING_STATE",
            key: "quotesLoadingStates",
            value: { quoteId: body.quote_id, loading: true }
          })

          updateDemandsNeeds({ body })
        },
        onDemandsNeedsClose: () => {
          dispatch({ type: "UPDATE_VALUE", key: "demandsNeeds", value: null })
          dispatch({ type: "UPDATE_VALUE", key: "demandsNeedsModal", value: false })
        },
        onQuoteNetworkDocumentsClose: () => {
          dispatch({ type: "UPDATE_VALUE", key: "quoteNetworkDocuments", value: null })
          dispatch({ type: "UPDATE_VALUE", key: "quoteNetworkDocumentsModal", value: false })
        },
        setQuoteNetworkDocuments: val => {
          dispatch({ type: "UPDATE_VALUE", key: "quoteNetworkDocuments", value: val })
          dispatch({ type: "UPDATE_VALUE", key: "quoteNetworkDocumentsModal", value: true })
        },
        setDemandsNeedsEdit: val => {
          dispatch({ type: "UPDATE_VALUE", key: "demandsNeeds", value: val })
          dispatch({ type: "UPDATE_VALUE", key: "demandsNeedsModal", value: true })
        },
        setDeleteQuoteModal: (shouldShow = false, quoteId = null) => {
          if (shouldShow && quoteId)
            return dispatch({
              type: "UPDATE_VALUE",
              key: "deleteQuoteModal",
              value: { show: shouldShow, quoteId }
            })

          dispatch({
            type: "UPDATE_VALUE",
            key: "deleteQuoteModal",
            value: { show: false, quoteId: null }
          })
        },
        setSelectedQuote: value => dispatch({ type: "UPDATE_VALUE", key: "selectedQuote", value }),
        setEligibilityModal: value =>
          dispatch({ type: "UPDATE_VALUE", key: "eligibilityModal", value }),
        setSelectedEditQuote: value =>
          dispatch({ type: "UPDATE_VALUE", key: "selectedEditQuote", value }),
        onQuoteCopy: quoteId => copyQuote({ body: { quote_id: quoteId } }),
        onDeleteQuote: () => {
          if (deleteQuoteModal?.quoteId)
            deleteQuote({ body: { quote_id: deleteQuoteModal?.quoteId } })
        },
        onPushToPortal: quoteId =>
          dispatch({
            type: "UPDATE_VALUE",
            key: "quotePushedToClientID",
            value: quoteId
          }),
        onQuoteRefresh: () => {},
        getQuoteLoadingState: quoteId =>
          quotesLoadingStates.find(item => item.quoteId === quoteId)
            ? quotesLoadingStates.find(item => item.quoteId === quoteId).loading
            : false,
        onApplicantsChange: (checked, member) => {
          const providerKey = get(selectedEditQuote, "provider.provider_key")
          const nextSelectedEditQuoteMembers = selectedEditQuoteMembers.filter(
            _ => _.member_id !== member.member_id
          )

          // Do not remove if the last applicant
          if (!checked && !nextSelectedEditQuoteMembers.length) {
            dispatch({
              type: "UPDATE_VALUE",
              key: "showNoApplicantsError",
              value: true
            })
            return
          }

          if (showNoApplicantsError)
            dispatch({
              type: "UPDATE_VALUE",
              key: "showNoApplicantsError",
              value: false
            })

          switch (providerKey) {
            case "AVIVA":
            case "AXA":
            case "BUPA":
              updateQuoteApplicants(checked, member)
              break
            case "EXETER":
            case "VITALITYHEALTH":
            default:
              // If no adults, show error and skip removing member
              if (!checked && nextSelectedEditQuoteMembers.every(_ => _.rate !== "adult")) {
                dispatch({
                  type: "UPDATE_VALUE",
                  key: "showNoChildOnlyPolicyError",
                  value: true
                })
              } else {
                if (showNoChildOnlyPolicyError)
                  dispatch({
                    type: "UPDATE_VALUE",
                    key: "showNoChildOnlyPolicyError",
                    value: false
                  })
                updateQuoteApplicants(checked, member)
              }
              break
          }
        },
        getApplicantNameByType: (type, filtering = "included") =>
          getName({ data: getApplicantByType(type, filtering), hasMiddle: true, hasTitle: false }),
        providerInfoModal,
        setProviderInfoModal: value =>
          dispatch({
            type: "UPDATE_VALUE",
            key: "providerInfoModal",
            value
          }),
        dataQuoteTable,
        onDealCodesChange: (checked, quoteDealCode) => updateQuoteDealCodes(checked, quoteDealCode)
      }}
    >
      {children}
    </Provider>
  )
}

QuotationSummaryProvider.propTypes = {
  children: PropTypes.any
}

export default QuotationSummaryProvider
